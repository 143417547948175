import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '@/views/Home.vue';
import About from "@/views/About.vue";
// import Events from "@/views/Events.vue";
import WhereToBuy from "@/views/WhereToBuy.vue";
import Contacts from "@/views/Contacts.vue";

Vue.use(VueRouter)

export const routes: Array<RouteConfig> = [
  {
    path: '*',
    redirect: { name: 'Home' }
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about-us',
    name: 'About',
    component: About
  },
  // {
  //   path: '/events',
  //   name: 'Events',
  //   component: Events
  // },
  {
    path: '/where-to-buy',
    name: 'Where To Buy',
    component: WhereToBuy
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: Contacts
  },
  {
    path: `/collections/:collectionSlug/prodotti`,
    redirect: `/collections/:collectionSlug`
  },
  {
    path: `/collections/:collectionSlug/prodotti/:itemSlug`,
    name: 'itemViewer',
    component: () => import(/* webpackChunkName: "collectionItem" */ '../views/Items/ItemView.vue')
  },
  {
    path: `/collections/:collectionSlug/in-evidenza`,
    redirect: `/collections/:collectionSlug`
  },
  {
    path: `/collections/:collectionSlug`,
    name: `collectionView`,
    component: () => import(/* webpackChunkName: "collections" */ '../views/collections/CollectionPreviewView.vue')
  },
  {
    path: `/collections/:collectionSlug/:categorySlug`,
    name: 'collectionSection',
    component: () => import(/* webpackChunkName: "collections" */ '../views/collections/CollectionView.vue')
  },
  {
    path: `/collections`,
    redirect: { name: 'Home' }
  },
  {
    path: `/reset-password`,
    name: 'passwordReset',
    component: () => import(/* webpackChunkName: "passwordReset" */ '../components/user/ResetPassword.vue')
  },
  {
    path: `/forgot-password`,
    name: 'forgotPassword',
    component: () => import(/* webpackChunkName: "passwordReset" */ '../components/user/ForgotPassword.vue')
  },
];

const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router
