










































import Component from "vue-class-component";
import Vue from "vue";
import {Prop} from "vue-property-decorator";
import {Media} from "@/store/modules/strapi/types";

@Component({
})
export default class AppSlideshow extends Vue{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Prop() slides!: Array<Media>;
  @Prop({default: 3}) secondsOnSlide!: number;
  @Prop({default: true}) fullscreen!: boolean;
  @Prop({default: 1000}) transitionDuration!: number;

  currentIndex = 0;

  mediaUrl(url: string) {
    return url ? `${process.env.VUE_APP_API_URI}${url}` : null;
  }

  get isImage(){
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (this.image as any).formats !== null;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getSrcset(formats: any): string {
    const srcset: string[] = [];
    for (const size in formats) {
      // eslint-disable-next-line no-prototype-builtins
      if (formats.hasOwnProperty(size)) {
        srcset.push(
            `${process.env.VUE_APP_API_URI}${formats[size].url} ${formats[size].width}w`
        );
      }
    }

    return srcset.join(',\n');
  }

  nextSlide(delayInSeconds = -1, accountForAnimation = false){
    if(delayInSeconds < 0.1) {
      delayInSeconds = this.secondsOnSlide;
    }
    let delay = delayInSeconds * 1000;
    if(accountForAnimation){
      delay = Math.floor(delay) - this.transitionDuration;
    }
    setTimeout(() => this.currentIndex++, delay);
  }

  get image() {
    return this.slides[Math.abs(this.currentIndex) % this.slides.length];
  }
}
