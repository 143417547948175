










































































import Vue from "vue";
import Component from "vue-class-component";
import {Strapi} from "@/store/modules/strapi/service";
import {CompanyInfo} from "@/store/modules/strapi/types";
import {MetaInfo} from "vue-meta";

@Component({
  metaInfo(): MetaInfo {
    return (this as Contacts).metaTags;
  }
})
export default class Contacts extends Vue {
  companyInfo: CompanyInfo|null = null;

  get metaTags(): MetaInfo {
    return {
      title: this.metaTitle,
      meta: [
        { name: 'description', content: 'Trovaci sui canali social o contattaci via email o telefono.' }
      ]
    };
  }

  get metaTitle() {
    return this.$t('contacts').toString();
  }

  async beforeCreate() {
    this.companyInfo = await Strapi.instance().getCompanyInfo()
        .then(r => r.data)
  }

  get hasAnySocialLink(): boolean {
    return this.companyInfo?.socials != null
        && (this.companyInfo.socials.facebook != null
            || this.companyInfo.socials.instagram != null
            || this.companyInfo.socials.twitter != null)
  }
}
