











import Component from "vue-class-component";
import Vue from "vue";
import {AboutPageBlock, AboutPageMediaContent} from "@/components/about/about-page-block";
import {Prop} from "vue-property-decorator";

@Component({
})
export default class AboutPageMediaComponent extends Vue implements AboutPageBlock {
  @Prop() readonly content!: AboutPageMediaContent;
  readonly vueComponentName: string = AboutPageMediaComponent.name;

  get mediaContent(){
    if(this.content.content){
      this.content.content.url = process.env.VUE_APP_API_URI + this.content.content.url;
    }
    return this.content.content;
  }
}
