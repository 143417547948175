
import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import VueScrollTo from 'vue-scrollto';
import LoginManager from "@/login-manager";
import i18n from "@/i18n";
import VueMeta from "vue-meta";

Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})

Vue.config.productionTip = false;

Vue.use(VueMeta);

new Vue({
  router,
  i18n,
  render: h => h(App),
  beforeCreate: async () => {
    await LoginManager.instance().restoreLogin();
  }
}).$mount('#app', true);
