import {
    AboutPageContent,
    AvailableCollections,
    CollectionItem,
    CollectionQuery,
    CompanyInfo,
    EventDescription,
    HomepageContentQuery,
    LegalDocs,
    LoginPayload,
    LoginResult, PasswordResetPayload, PasswordResetRequestPayload, RegistrationPayload,
    Store, User
} from "@/store/modules/strapi/types";
import {
    ApolloClient,
    ApolloLink,
    ApolloQueryResult,
    gql, HttpLink,
    InMemoryCache,
    NormalizedCacheObject
} from "@apollo/client/core";
import axios, {AxiosInstance, AxiosResponse} from "axios";
import LoginManager from "@/login-manager";
import i18n from "@/i18n";
/* eslint @typescript-eslint/no-var-requires: "off" */
const { EventBus } = require("@/event-bus");

export interface StrapiService {
    fetchAvailableCollections(): Promise<ApolloQueryResult<AvailableCollections>>;
    fetchCollection(name: string): Promise<ApolloQueryResult<CollectionQuery>>;
    fetchItem(slug: string): Promise<CollectionItem | null>;
    getAbout(locale: string): Promise<AxiosResponse<AboutPageContent>>;
    getHome(): Promise<ApolloQueryResult<HomepageContentQuery>>;
    getEvents(): Promise<AxiosResponse<Array<EventDescription>>>;
    getStores(): Promise<AxiosResponse<Array<Store>>>;
    getCompanyInfo(): Promise<AxiosResponse<CompanyInfo>>;
    getLegalDocs(): Promise<AxiosResponse<LegalDocs>>;

    /* Auth */
    login(payload: LoginPayload): Promise<AxiosResponse<LoginResult>>;
    register(payload: RegistrationPayload): Promise<AxiosResponse<LoginResult>>;
    resetPasswordRequest(payload: PasswordResetRequestPayload): Promise<AxiosResponse>;
    resetPassword(payload: PasswordResetPayload): Promise<AxiosResponse>;
    deleteUser(): Promise<AxiosResponse>;
    getSelf(): Promise<AxiosResponse<User>>;

    /* Maintenance */
    clearApolloCache(): Promise<void>;
}

export class Strapi implements StrapiService {

    private static _instance: Strapi;
    private readonly apolloClient: ApolloClient<NormalizedCacheObject>;
    private readonly axiosInstance: AxiosInstance;

    private constructor() {
        const baseUrl = process.env.VUE_APP_API_URI;
        this.apolloClient = new ApolloClient({
            link: this.createApolloClientLink(baseUrl),
            cache: new InMemoryCache()
        });
        this.axiosInstance = axios.create({
            baseURL: baseUrl
        });

        EventBus.$on('login-event', async () => await Strapi.instance().clearApolloCache())
    }

    private createApolloClientLink(baseUrl: string) {
        const graphqlEndpoint = `${baseUrl}/graphql`;
        const httpLink = new HttpLink({ uri: graphqlEndpoint });

        const authLink = new ApolloLink((operation, forward) => {
            operation.setContext({
                headers: {
                    Authorization: Strapi.authHeader
                }
            });
            return forward(operation);
        });

        return authLink.concat(httpLink)
    }

    get axiosClient() {
        this.axiosInstance.defaults.headers.common['Authorization'] = Strapi.authHeader;
        return this.axiosInstance;
    }

    public static instance(){
        if(!Strapi._instance){
            Strapi._instance = new Strapi();
        }
        return Strapi._instance;
    }

    get locale() {
        return i18n.locale ?? 'it';
    }

    fetchAvailableCollections(): Promise<ApolloQueryResult<AvailableCollections>> {
        return this.apolloClient
            .query({
                query: gql`
                    query {
                        availableCollections: collections {
                            slug
                            name
                            season {
                                season
                                year
                            },
                            localizations {
                                locale
                                name
                            }
                        }
                    }`
            });
    }

    fetchCollection(slug: string): Promise<ApolloQueryResult<CollectionQuery>> {
        return this.apolloClient
            .query({
                query: gql`
                    query GetCollection($slug: String!){
                        collectionBySlug(slug: $slug)
                        {
                            name
                            items {
                                name
                                slug
                                categories {
                                    slug
                                }
                                variations {
                                    name
                                    color
                                    images(limit: 1) {
                                        url
                                        formats
                                    }
                                }
                            }
                            cover {
                                url
                                formats
                            }
                            localizations {
                                locale
                                name
                            }
                        }
                        collectionCategories: categories(where: {
                            items: {
                                parentCollection: {
                                    slug: $slug
                                }
                            }
                        }) {
                            name
                            slug
                            localizations {
                                locale
                                name
                            }
                        }
                    }`,
                variables: { slug: slug }
                });
    }

    fetchItem(slug: string): Promise<CollectionItem | null> {
        return this.apolloClient
        .query({
            query: gql`
                query GetItem($slug: String!){
                    itemBySlug(slug: $slug){
                        slug
                        name
                        sizes
                        productCode
                        author
                        categories {
                            slug
                        }
                        parentCollection {
                            name
                            slug
                        }
                        details {
                          materials {
                            material
                            insole
                            sole
                            slipcover
                          }
                          shapeAndLook {
                            heel
                            fantasy
                          }
                        }
                        variations {
                            name
                            color
                            availability {
                                size
                                quantity
                            }
                            images {
                                url
                                formats
                            }
                        }
                        localizations {
                            locale
                            parentCollection {
                                name
                            }
                            details {
                                materials {
                                    material
                                    insole
                                    sole
                                    slipcover
                                }
                                shapeAndLook {
                                    heel
                                    fantasy
                                }
                            }
                        }
                    }
                }`,
            variables: { slug: slug }
        })
        .then(result => {
            return result.data.itemBySlug
        });
    }

    async getAbout(locale: string): Promise<AxiosResponse<AboutPageContent>> {
        return await this.axiosClient.get<AboutPageContent>(`about-us?_locale=${locale}`);
    }

    async getEvents(): Promise<AxiosResponse<Array<EventDescription>>> {
        return await this.axiosClient.get<Array<EventDescription>>(`events?_sort=date&date_gte=${
            (new Date()).toISOString()
        }`);
    }

    async getStores(): Promise<AxiosResponse<Array<Store>>> {
        return await this.axiosClient.get<Array<Store>>(`stores`);
    }

    getHome(): Promise<ApolloQueryResult<HomepageContentQuery>> {
        return this.apolloClient
        .query({
            query: gql`
                query {
                    homepage {
                        backgrounds {
                            url
                            formats
                            name
                        }
                        showcaseCollection {
                            name
                            slug
                            locale
                            localizations {
                                locale
                                name
                            }
                        }
                    }
                }`
        });
    }

    async getCompanyInfo(): Promise<AxiosResponse<CompanyInfo>> {
        return await this.axiosClient.get<CompanyInfo>(`company-info`);
    }

    async getLegalDocs(): Promise<AxiosResponse<LegalDocs>> {
        return await this.axiosClient.get<LegalDocs>(`legal-documents`);
    }

    /* Auth */
    async login(payload: LoginPayload): Promise<AxiosResponse<LoginResult>> {
        return await this.axiosClient.post<LoginResult>(`auth/local`, {
            identifier: payload.identifier,
            password: payload.password,
        });
    }

    async register(payload: RegistrationPayload): Promise<AxiosResponse<LoginResult>> {
        return await this.axiosClient.post<LoginResult>('auth/local/register', {
            username: payload.email,
            email: payload.email,
            password: payload.password,
            firstName: payload.firstName,
            lastName: payload.lastName,
            vatNumber: payload.vatNumber,
            companyName: payload.companyName,
            acceptedTerms: payload.acceptedTerms,
            acceptedPrivacy: payload.acceptedPrivacy
        });
    }

    async resetPasswordRequest(payload: PasswordResetRequestPayload): Promise<AxiosResponse> {
        return await this.axiosClient.post('auth/forgot-password', {
            email: payload.email
        });
    }

    async resetPassword(payload: PasswordResetPayload): Promise<AxiosResponse> {
        return await this.axiosClient.post('auth/reset-password', {
            code: payload.code, // code contained in the reset link of step 3.
            password: payload.password,
            passwordConfirmation: payload.confirmPassword
        });
    }

    async deleteUser(): Promise<AxiosResponse> {
        return await this.axiosClient.delete('users/me');
    }

    async getSelf(): Promise<AxiosResponse<User>> {
        return await this.axiosClient.get<User>('users/me');
    }

    private static get authHeader() {
        const token = localStorage.getItem('jwt');
        return LoginManager.verifyToken(token) ? `Bearer ${token}` : '';
    }

    private $clearApolloCachePromise: Promise<void>|null = null;
    /* Maintenance */
    async clearApolloCache(): Promise<void>{
        if(!this.$clearApolloCachePromise){
            this.$clearApolloCachePromise =
                this.apolloClient.resetStore()
                    .then(() => {
                        console.warn('ApolloClient cache was cleared.');
                        this.$clearApolloCachePromise = null;
                    });
        }
        return await this.$clearApolloCachePromise;
    }
}
