



























import Component from "vue-class-component";
import {Watch} from "vue-property-decorator";
import Vue from "vue";

import {navbarStyles} from './navbar-styles'
import TheMenu from "@/components/navbar/TheMenu.vue";
/* eslint @typescript-eslint/no-var-requires: "off" */
const { EventBus } = require("@/event-bus");

@Component({
  components: {
    TheMenu
  }
})
export default class TheNavbar extends Vue {
  menuOpened = false;
  currentRoute = this.$route.fullPath;

  beforeCreate() {
    const locale = localStorage.getItem('preferredLocale');
    if(locale && (locale.startsWith('en') || locale.startsWith('it')) ){
      this.$i18n.locale = locale.startsWith('it') ? 'it' : 'en';
    } else {
      localStorage.setItem('preferredLocale', this.$i18n.locale);
    }
  }

  toggleMenu() {
    this.menuOpened = !this.menuOpened;
  }

  get currentStyleForRoute(): string {
    const style = navbarStyles.routeStyles.find(
        s => this.currentRoute.startsWith(s.path)
            && (!s.exactPath || this.currentRoute === s.path));
    return style ? style.classes : navbarStyles.fallbackClass;
  }

  @Watch('$route')
  onRouteChanged(){
    this.currentRoute = this.$route.fullPath;
    this.menuOpened = false;
  }

  changeLocale() {
    if(this.itLocaleSelected){
      this.$i18n.locale = 'en';
      localStorage.setItem('preferredLocale', 'en');
    } else {
      this.$i18n.locale = 'it';
      localStorage.setItem('preferredLocale', 'it');
    }
    EventBus.$emit('localeChanged');
  }

  get itLocaleSelected(){
    return this.$i18n.locale.startsWith('it');
  }

  get enLocaleSelected(){
    return this.$i18n.locale.startsWith('en');
  }
}
