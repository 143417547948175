import VueI18n from "vue-i18n";
import Vue from "vue";

Vue.use(VueI18n);

const messages = {
    'it': {
        goToCollectionMsg: 'Vai alla collezione',
        collections: 'Collezioni',
        whereToBuy: 'Dove trovarci',
        events: 'Eventi',
        aboutUs: 'La nostra storia',
        contacts: 'Contatti',
        login: 'Accedi',
        terms: 'Termini e Condizioni',
        cookiePolicy: 'Cookie Policy',
        privacyPolicy: 'Privacy Policy',
        userWelcome: 'Ciao',
        userWelcomeNoUser: 'Ciao, hai effettuato l\'accesso.',
        yourAccount: 'Il tuo account',
        logout: 'Logout',
        signup: 'Registrati',
        confirmPassword: 'Conferma password',
        firstName: 'Nome',
        lastName: 'Cognome',
        vatNumber: 'Partita IVA',
        cancel: 'Annulla',
        checkCredentials: 'Controlla che le credenziali siano giuste.',
        errorOccurredContact: 'Si è verificato un errore. Se il problema persiste contattateci.',
        requiredField: 'campo obbligatorio',
        inputValidEmail: 'inserisci una email valida',
        writePassword: 'inserisci la password',
        passwordComplexity: 'minimo 8 caratteri, massimo 32',
        differentPasswords: 'le password sono diverse',
        nameMaxLength : 'massimo 64 caratteri',
        notValidVat: 'non valido',
        signupValidationError: 'Controlla i dati inseriti',
        errorOccurred: 'Si è verificato un errore',
        emailTaken: 'L\'email è già in uso',
        accountDeleted: 'Account eliminato con successo.',
        thisIsYourData: 'questi sono i tuoi dati',
        fullName: 'Nome',
        changePassword: 'Cambia password',
        resetPasswordEmailConfirm: 'Verrà inviato un link alla tua email per re-impostare la password.',
        deleteAccount: 'Elimina account',
        deleteAccountConfirm: 'Non potrai più recuperare il tuo account, vuoi continuare?',
        yes: 'Sì',
        close: 'Chiudi',
        findUsOnSocials: 'Trovaci sui canali social',
        oppure: 'oppure',
        contactUs: 'Contattaci',
        findOutMore: 'Scopri di più',
        whereFindUs: 'Dove Trovarci',
        webSite: 'Sito web',
        filters: 'Filtri',
        categories: 'Categorie',
        images: 'Immagini',
        clotheSizes: 'Taglie',
        material: 'Materiale',
        fantasy: 'Fantasia',
        slipcover: 'Fodera',
        insole: 'Soletta',
        heel: 'Tacco',
        sole: 'Suola',
        productCode: 'Codice articolo',
        details: 'Dettagli',
        companyName: 'Nome azienda',
        companyNameMaxLength: 'massimo 254 caratteri',
        confirmEmail: 'Un ultimo passaggio',
        confirmEmailBody: 'Per favore conferma la tua email seguendo il link che ti abbiamo inviato.\nPotrai fare il login solo dopo aver confermato la email.',
        emailConfirmed: 'Email Confermata',
        emailConfirmedBody: 'Grazie per aver confermato la tua email. Ora puoi effettuare il login.',
        resetYourPassword: 'Reimposta la password',
        resetPasswordButton: 'Reimposta',
        forgotPassword: 'Ho dimenticato la password',
        forgotPasswordPleaseFollowLink: 'Se sei registrato invieremo un link alla tua email per poter reimpostare la tua password.',
        sentForgotEmail: 'Controlla la tua email per reimpostare la password.',
        passwordResetSuccessful: 'La tua password è stata reimpostata.',
        backToHome: 'Torna alla homepage',
        forgotYourPassword: 'Password dimenticata'
    },
    'en': {
        goToCollectionMsg: 'See the collection',
        collections: 'Collections',
        whereToBuy: 'Where to buy',
        events: 'Events',
        aboutUs: 'Our history',
        contacts: 'Contacts',
        login: 'Login',
        terms: 'Terms and Conditions',
        cookiePolicy: 'Cookie Policy',
        privacyPolicy: 'Privacy Policy',
        userWelcome: 'Hi',
        userWelcomeNoUser: 'Hi, you are logged in.',
        yourAccount: 'Your account',
        logout: 'Logout',
        signup: 'Sign up',
        confirmPassword: 'Confirm password',
        firstName: 'First name',
        lastName: 'Last name',
        vatNumber: 'VAT Number',
        cancel: 'Cancel',
        checkCredentials: 'Check your credentials. Incorrect login.',
        errorOccurredContact: 'An error occurred. Try again later or contact us.',
        requiredField: 'required field',
        inputValidEmail: 'not a valid email',
        writePassword: 'write your password',
        passwordComplexity: 'length between 8 and 32 characters',
        differentPasswords: 'passwords do not match',
        nameMaxLength : 'max length of 64 characters',
        notValidVat: 'not valid',
        signupValidationError: 'Check provided data',
        errorOccurred: 'An error occurred',
        emailTaken: 'Email is already taken',
        accountDeleted: 'User successfully deleted.',
        thisIsYourData: 'this is your data',
        fullName: 'Full name',
        changePassword: 'Change password',
        resetPasswordEmailConfirm: 'You will receive an email with a link to reset your password.',
        deleteAccount: 'Delete account',
        deleteAccountConfirm: 'You won\'t be able to recover your account. Continue?',
        yes: 'Yes',
        close: 'Close',
        findUsOnSocials: 'Visit us on:',
        oppure: 'or',
        contactUs: 'Contact us',
        findOutMore: 'Find out more',
        whereFindUs: 'Where To Buy',
        webSite: 'Website',
        filters: 'Filters',
        categories: 'Categories',
        images: 'Images',
        clotheSizes: 'Sizes',
        material: 'Material',
        fantasy: 'Fantasy',
        slipcover: 'Slipcover',
        insole: 'Insole',
        heel: 'Heel',
        sole: 'Sole',
        productCode: 'Product code',
        details: 'Details',
        companyName: 'Company name',
        companyNameMaxLength: 'maximum length of 254 characters',
        confirmEmail: 'One last step',
        confirmEmailBody: 'Please confirm your email by following the link we sent you. You will be able to login only after confirming your email.',
        emailConfirmed: 'Email Confirmed',
        emailConfirmedBody: 'Thank for confirming your email. You may now sign-in.',
        resetYourPassword: 'Reset your password',
        resetPasswordButton: 'Reset',
        forgotPassword: 'Reset your password',
        forgotPasswordPleaseFollowLink: 'If you are registered we will send you an email with a link to reset your password.',
        sentForgotEmail: 'Check your email to reset your password.',
        passwordResetSuccessful: 'Your password was reset successfully.',
        backToHome: 'Back to home',
        forgotYourPassword: 'Forgot password'
    }
};

const i18n = new VueI18n({
    locale: 'it', // set locale
    fallbackLocale: 'en', // set fallback locale
    messages, // set locale messages
});

export default i18n;
