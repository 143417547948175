







import Component from "vue-class-component";
import Vue from "vue";
import {AboutPageBlock, AboutPageSlideshowContent} from "@/components/about/about-page-block";
import {Prop} from "vue-property-decorator";
import AppSlideshow from "@/components/slideshow/AppSlideshow.vue";

@Component({
  components: {AppSlideshow}
})
export default class AboutPageSlideshowComponent extends Vue implements AboutPageBlock {
  @Prop() readonly content!: AboutPageSlideshowContent;
  readonly vueComponentName: string = AboutPageSlideshowComponent.name;
}
