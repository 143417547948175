




































import Vue from "vue";
import {HomepageContent, HomepageContentQuery} from "@/store/modules/strapi/types";
import {Strapi} from "@/store/modules/strapi/service";
import {ApolloQueryResult} from "@apollo/client/core";
import Component from "vue-class-component";
import AppSlideshow from "@/components/slideshow/AppSlideshow.vue";
import {MetaInfo} from "vue-meta";

@Component({
  components: {AppSlideshow},
  metaInfo(): MetaInfo {
    return (this as Home).metaTags;
  }
})
export default class Home extends Vue {

  homeContentQuery: ApolloQueryResult<HomepageContentQuery> | null = null;

  get metaTags(): MetaInfo {
    return {
      meta: [
        { name: 'description', content: `Scopri la nuova collezione${this.collection ? ' ' + this.collection.name: ''}.`  }
      ]
    };
  }

  async beforeCreate() {
    this.homeContentQuery = await Strapi.instance().getHome();
  }

  get homeContent(): HomepageContent|null {
    return this.homeContentQuery?.data?.homepage as HomepageContent;
  }

  get collection() {
    return this.homeContent?.showcaseCollection;
  }

  get collectionName(){
    if(!this.$i18n.locale.startsWith('it')){
      const locale = this.collection?.localizations.find(l => l.locale.startsWith('en'));
      if(locale){
        return locale.name;
      }
    }
    return this.collection?.name;
  }

  get backgrounds() {
    return this.homeContent?.backgrounds ?? [];
  }

}
