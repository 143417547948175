

























/* eslint @typescript-eslint/no-var-requires: "off" */
import Vue from "vue";
import Component from "vue-class-component";
import LoginManager from "@/login-manager";
import {User} from "@/store/modules/strapi/types";
import {Strapi} from "@/store/modules/strapi/service";
const { EventBus } = require("@/event-bus");

@Component({})
export default class TheMenuLogin extends Vue {

  loggedIn = false;
  user: User|null = null;

  async mounted() {
    await this.pollLogin();
    EventBus.$on('login-event', this.pollLogin);
  }

  private async pollLogin(){
    this.loggedIn = LoginManager.instance().isLoggedIn();
    if(this.loggedIn) {
      this.user = await Strapi.instance().getSelf().then(r => r.data);
    }
  }

  async logout(){
    LoginManager.instance().logout();
  }

  openLoginModal(){
    EventBus.$emit('login-modal');
  }

  toggleAccountModal(){
    EventBus.$emit('account-modal');
  }
}
