











import Component from "vue-class-component";
import Vue from "vue";
import Modal from "@/components/modals/Modal.vue";
/* eslint @typescript-eslint/no-var-requires: "off" */
const { EventBus } = require("@/event-bus");

@Component({
  components: {
    Modal
  }
})
export default class EmailConfirmationSuccess extends Vue {
  closeModal() {
    EventBus.$emit('email-confirmed-modal-close');
    console.debug('Modal close request')
  }
}
