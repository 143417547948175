









import Component from "vue-class-component";
import Vue from "vue";
import {AboutPageBlock, AboutPageParagraphContent} from "@/components/about/about-page-block";
import {Prop} from "vue-property-decorator";

@Component({
})
export default class AboutPageParagraphComponent extends Vue implements AboutPageBlock {
  @Prop() readonly content!: AboutPageParagraphContent;
  readonly vueComponentName: string = AboutPageParagraphComponent.name;
}
