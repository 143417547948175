































































































/* eslint @typescript-eslint/no-var-requires: "off" */
import Component from "vue-class-component";
import {Prop, Watch} from "vue-property-decorator";
import Vue from "vue";

import {AvailableCollections, Collection, CompanyInfo, LegalDocs, SeasonPeriod} from "@/store/modules/strapi/types";
import {ApolloQueryResult} from "@apollo/client/core";
import {Strapi} from "@/store/modules/strapi/service";
import TheMenuLogin from "@/components/navbar/TheMenuLogin.vue";
const { EventBus } = require("@/event-bus");

@Component({
  components: {TheMenuLogin}
})
export default class TheMenu extends Vue {

  @Prop() menuOpened!: boolean;
  expandCollections = false;

  availableCollectionsQuery: ApolloQueryResult<AvailableCollections> | null = null;
  legalDocs: LegalDocs|null = null;
  companyInfo: CompanyInfo|null = null;

  @Watch('menuOpened')
  onMenuToggle() {
    this.expandCollections = false;
  }

  async beforeCreate() {

    EventBus.$on('login-event',
        async () => {
          await Strapi.instance().clearApolloCache();
          this.availableCollectionsQuery = await Strapi.instance().fetchAvailableCollections()
        });

    this.availableCollectionsQuery = await Strapi.instance().fetchAvailableCollections();
    this.legalDocs = await Strapi.instance().getLegalDocs()
        .then(r => r.data)
        .then(docs => {
          docs.cookies.url = process.env.VUE_APP_API_URI + docs.cookies.url;
          docs.privacy.url = process.env.VUE_APP_API_URI + docs.privacy.url;
          docs.terms.url = process.env.VUE_APP_API_URI + docs.terms.url;
          return docs;
        });
    this.companyInfo = await Strapi.instance().getCompanyInfo().then(r => r.data);
  }

  get collections() {
    return Array.from(this.availableCollectionsQuery?.data?.availableCollections ?? [])
        .sort(TheMenu.sortCollections);
  }

  collectionName(collection: Collection){
    if(!this.$i18n.locale.startsWith('it')){
      const locale = collection.localizations.find(l => l.locale.startsWith('en'));
      if(locale){
        return locale.name;
      }
    }
    return collection.name;
  }

  toggleCollections() {
    this.expandCollections = !this.expandCollections;
  }

  emitMenuClick(){
    this.$emit('menu-click');
  }

  private static sortCollections(a: Collection, b: Collection) {
    return (b.season.year - a.season.year) + TheMenu.compareSeasonPeriod(a.season.season, b.season.season);
  }

  private static compareSeasonPeriod(a: SeasonPeriod, b: SeasonPeriod) {
    if (a === b) {
      return 0;
    }

    const order = [
      SeasonPeriod.Primavera,
      SeasonPeriod.PrimaveraEstate,
      SeasonPeriod.Estate,
      SeasonPeriod.Autunno,
      SeasonPeriod.AutunnoInverno,
      SeasonPeriod.Inverno
    ];
    return order.indexOf(b) - order.indexOf(a) > 0 ? 1 : -1;
  }
}
