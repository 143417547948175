/* eslint @typescript-eslint/no-var-requires: "off" */
import {LoginResult} from "@/store/modules/strapi/types";
import * as jwt from "jsonwebtoken";
const { EventBus } = require("@/event-bus");

export default class LoginManager {
    private static _instance: LoginManager;

    /* eslint-disable-next-line @typescript-eslint/no-empty-function */
    private constructor() {
    }

    public static instance(){
        if(!LoginManager._instance){
            LoginManager._instance = new LoginManager();
        }
        return LoginManager._instance;
    }

    public async restoreLogin() {
        const jwtFromStorage = localStorage.getItem('jwt');
        if(jwtFromStorage){
            const isValid = LoginManager.verifyToken(jwtFromStorage);
            if(jwtFromStorage && isValid) {
                LoginManager.setToken(jwtFromStorage);
                EventBus.$emit('login-event');
            } else {
                this.logout();
            }
        }
    }

    public static verifyToken(token: string|null): boolean{
        if(!token){
            return false;
        }
        const decoded = jwt.decode(token);
        if(!decoded){
            return false;
        }
        const now = Date.now();
        return !(now < decoded.iat * 1000 || now >= decoded.exp * 1000);
    }

    public saveLogin(login: LoginResult){
        LoginManager.setToken(login.jwt);
    }

    public logout(){
        LoginManager.setToken(null);
        EventBus.$emit('login-event');
    }

    get token() {
        return localStorage.getItem('jwt');
    }

    private static setToken(value: string|null) {
        if(value!=null){
            localStorage.setItem('jwt', value);
        } else {
            localStorage.removeItem('jwt');
        }
    }

    public isLoggedIn() {
        return LoginManager.verifyToken(this.token)
    }

    public destroy() {
        this.logout();
        delete LoginManager._instance;
    }

}
