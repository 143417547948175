














import Component from "vue-class-component";
import Vue from "vue";
import {Strapi} from "@/store/modules/strapi/service";
import {
  AboutPageContent,
  AboutPageContentBlock,
  AboutPageParagraphContentBlock
} from "@/store/modules/strapi/types";
import AboutPageParagraphComponent from "@/components/about/AboutPageParagraphComponent.vue";
import {AboutPageBlock, AboutPageMediaContent, AboutPageParagraphContent} from "@/components/about/about-page-block";
import AboutPageMediaComponent from "@/components/about/AboutPageMediaComponent.vue";
import AboutPageSlideshowComponent from "@/components/about/AboutPageSlideshowComponent.vue";
import {MetaInfo} from "vue-meta";
/* eslint @typescript-eslint/no-var-requires: "off" */
const { EventBus } = require("@/event-bus");

@Component({
  components: {
    AboutPageParagraphComponent,
    AboutPageMediaComponent,
    AboutPageSlideshowComponent
  },
  metaInfo(): MetaInfo {
    return (this as About).metaTags;
  }
})
export default class About extends Vue {

  content: AboutPageContent|null = null;
  blocks: Array<AboutPageBlock> = [];

  get metaTags(): MetaInfo {
    return {
      title: this.metaTitle,
      meta: [
        { name: 'description', content: this.metaDescription  },
      ]
    };
  }

  get metaTitle() {
    if(this.content){
      return this.content.title.trim();
    }
    return 'La nostra storia';
  }

  get metaDescription() {
    let metaDesc = this.metaTitle;
    if(this.blocks.length > 0) {
      const paragraphBlock = this.blocks.find((block: AboutPageBlock) => this.isParagraph(block));
      if(paragraphBlock){
        const content = (paragraphBlock.content as AboutPageParagraphContent).content;
        if(content) {
          const lastDotIndex = content.lastIndexOf('.', 160);
          if(lastDotIndex > 0) {
            metaDesc = content.substring(0, lastDotIndex + 1).trim();
          }
        }
      }
    }
    return metaDesc;
  }

  private async getContents() {
    this.content = await Strapi.instance().getAbout(this.$i18n.locale).then(r => r.data);
    this.blocks = [];
    if(this.content) {
      for (const _block of this.content?.content) {
        const block = this.getComponent(_block);
        if(block){
          this.blocks.push(block);
        }
      }
    }
  }

  async beforeCreate(){
    this.content = await Strapi.instance().getAbout(this.$i18n.locale).then(r => r.data);
    this.blocks = [];
    if(this.content){
      for (const _block of this.content?.content) {
        const block = this.getComponent(_block);
        if(block){
          this.blocks.push(block);
        }
      }
    }
  }

  async mounted() {
    EventBus.$on('localeChanged', async () => await this.getContents());
  }

  getComponent(block: AboutPageContentBlock): AboutPageBlock|null{
    // blocks.slideshow
    // blocks.paragraph
    // blocks.media
    switch (block.__component) {
      // case 'blocks.slideshow':
      //   return {
      //     vueComponentName: 'AboutPageSlideshowComponent',
      //     content: (block as unknown) as AboutPageSlideshowContent
      //   };
      case 'blocks.paragraph': {
        return {
          vueComponentName: 'AboutPageParagraphComponent',
          content: block as AboutPageParagraphContentBlock
        };
      }
      case 'blocks.media': {
        return {
          vueComponentName: 'AboutPageMediaComponent',
          content: block as AboutPageMediaContent
        };
      }
      default:
        return null;
    }
  }

  isParagraph(block: any): block is AboutPageParagraphContentBlock{
    return 'content' in block;
  }

}
