export interface Media {
    name:              string;
    alternativeText:   string;
    caption:           string;
    width:             number;
    height:            number;
    formats:           MediaFormats;
    hash:              string;
    ext:               string;
    mime:              string;
    size:              number;
    url:               string;
    previewUrl:        null;
    provider:          string;
    provider_metadata: null;
    created_at:        Date;
    updated_at:        Date;
}

export interface MediaFormats {
    large:     MediaFormat;
    small:     MediaFormat;
    medium:    MediaFormat;
    thumbnail: MediaFormat;
}

export interface MediaFormat {
    ext:    string;
    url:    string;
    hash:   string;
    mime:   string;
    name:   string;
    path:   null;
    size:   number;
    width:  number;
    height: number;
}

export interface ItemMaterials {
    material: string | null;
    lining: string | null;
    insole: string | null;
    sole: string | null;
    slipcover: string | null;
}

export interface ItemShapeAndLook {
    tip: string | null;
    heel: string | null;
    closure: string | null;
    fantasy: string | null;
    details: string | null;
}

export interface ItemDetails {
    materials: ItemMaterials;
    shapeAndLook: ItemShapeAndLook;
}

export interface ItemVariation {
    name: string;
    color: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    images: any[];
    productCode: string;
    availability: Array<ItemSizeAvailability>;
}

export interface ItemSizeAvailability {
    size: number;
    quantity: number;
}

export interface CollectionItem {
    id: unknown;
    locale: string;
    name: string;
    slug: string;
    productCode: string;
    description: string;
    author: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    details: any;
    sizes: string;
    variations: Array<ItemVariation>;
    categories: Array<Category>;
    parentCollection: Collection;
    localizations: Array<CollectionItem>;
}

export enum SeasonPeriod {
    Primavera = "Primavera",
    Estate = "Estate",
    Autunno = "Autunno",
    Inverno = "Inverno",
    PrimaveraEstate = "PrimaveraEstate",
    AutunnoInverno = "AutunnoInverno"
}

export interface Season {
    season: SeasonPeriod;
    year: number;
}

export interface Collection {
    name: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    cover: any;
    slug: string;
    season: Season;
    items: CollectionItem[];
    locale: string;
    localizations: Array<Collection>;
}

export interface Category {
    name: string;
    slug: string;
    locale: string;
    localizations: Array<Category>;
}

export interface CollectionQuery {
    collectionBySlug: Collection | null;
    collectionCategories: Array<Category>;
}

export interface AvailableCollections {
    availableCollections: Array<Collection>;
}

export interface AboutPageContentBlock {
    __component: string;
}

export interface AboutPageParagraphContentBlock extends AboutPageContentBlock{
    title: string;
    content: string;
}

export interface AboutPageMediaContentBlock extends AboutPageContentBlock{
    content: {
        name: string;
        url: string;
        width: number;
        height: number;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        formats: Record<string, any>;
    };
}

export interface AboutPageContent {
    title: string;
    content: Array<AboutPageContentBlock>;
}

export interface HomepageContentQuery {
    homepage: HomepageContent;
}

export interface HomepageContent {
    backgrounds:        Media[];
    showcaseCollection: ShowcaseCollection;
}

export interface ShowcaseCollection {
    name: string;
    slug: string;
    locale: string;
    localizations: Array<ShowcaseCollection>;
}

export interface EventDescription {
    id:           number;
    name:         string;
    date:         Date;
    eventLink:    string;
    description:  string;
    published_at: Date;
    created_at:   Date;
    updated_at:   Date;
}

export interface Store {
    id:           number;
    name:         string;
    address:      string;
    phone:        string;
    email:        null;
    website:      string;
    region: string;
    published_at: Date;
    created_at:   Date;
    updated_at:   Date;
    socials:      Socials|null;
}

export interface CompanyInfo {
    id:           number;
    name:         string;
    vatNumber:    string;
    address:      string;
    sdiNumber:    string;
    cfNumber:     string;
    phone:        string;
    primaryEmail: string;
    published_at: Date;
    created_at:   Date;
    updated_at:   Date;
    socials:      Socials|null;
}

export interface Socials {
    id:   number;
    facebook:  string;
    instagram: string;
    twitter:   string;
}

export interface LegalDocs {
    id:           number;
    published_at: Date;
    created_at:   Date;
    updated_at:   Date;
    privacy:      LegalDoc;
    cookies:      LegalDoc;
    terms:        LegalDoc;
}

export interface LegalDoc {
    id:                number;
    name:              string;
    hash:              string;
    ext:               string;
    mime:              string;
    size:              number;
    url:               string;
    provider:          string;
    created_at:        Date;
    updated_at:        Date;
}

export interface LoginPayload {
    identifier: string;
    password: string;
}

export interface LoginResult {
    jwt:  string;
    user: User;
}

export interface User {
    id:         number;
    username:   string;
    email:      string;
    provider:   string;
    confirmed:  boolean;
    blocked:    boolean;
    role:       Role;
    created_at: Date;
    updated_at: Date;

    companyName: string;
    vatNumber: string;
    firstName: string;
    lastName:  string;
}

export interface Role {
    id:          number;
    name:        string;
    description: string;
    type:        string;
}

export interface RegistrationPayload {
    email: string;
    password: string;
    confirmPassword: string;
    firstName: string;
    lastName: string;
    companyName: string;
    vatNumber: string;
    acceptedTerms: boolean;
    acceptedPrivacy: boolean;
}

export interface PasswordResetRequestPayload {
    email: string;
}

export interface PasswordResetPayload {
    code: string;
    password: string;
    confirmPassword: string;
}
