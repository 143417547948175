export interface NavRouteStyle {
    path: string;
    exactPath: boolean;
    classes: string;
}

const routeStyles: NavRouteStyle[] = [
    { path: '/', classes: 'home', exactPath: true }
];

export interface NavbarStyles {
    fallbackClass: string;
    routeStyles: NavRouteStyle[];
}

export const navbarStyles: NavbarStyles = {
    fallbackClass: 'default',
    routeStyles
};