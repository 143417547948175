












































import Vue from "vue";
import Component from "vue-class-component";
import {Store} from "@/store/modules/strapi/types";
import {Strapi} from "@/store/modules/strapi/service";
import {MetaInfo} from "vue-meta";

@Component({
    metaInfo(): MetaInfo {
      return (this as WhereToBuy).metaTags;
    }
})
export default class WhereToBuy extends Vue {
  stores: Array<Store> = [];
  groupedStores: Array<{region: string; stores: Array<Store>}> = []

  regions = {
    'Abruzzo': 'Abruzzo',
    'Basilicata': 'Basilicata',
    'Calabria': 'Calabria',
    'Campania': 'Campania',
    'EmiliaRomagna': 'Emilia Romagna',
    'FriuliVeneziaGiulia': 'Friuli-Venezia Giulia',
    'Lazio': 'Lazio',
    'Liguria': 'Liguria',
    'Lombardia': 'Lombardia',
    'Marche': 'Marche',
    'Molise': 'Molise',
    'Piemonte': 'Piemonte',
    'Puglia': 'Puglia',
    'Sardegna': 'Sardegna',
    'Sicilia': 'Sicilia',
    'Toscana': 'Toscana',
    'TrentinoAltoAdige': 'Trentino-Alto Adige',
    'Umbria': 'Umbria',
    'ValledAosta': 'Valle \'Aosta',
    'Veneto': 'Veneto'
  }

  get metaTags(): MetaInfo {
    return {
      title: this.title,
      meta: [
        { name: 'description', content: 'Dove trovare i nostri prodotti.'  },
      ]
    };
  }

  get title(): string {
    return this.$t('whereFindUs').toString();
  }

  async beforeCreate() {
    this.stores = await Strapi.instance().getStores()
        .then(r => r.data)
        .then(stores => stores.sort((a, b) => a.name.localeCompare(b.name)));

    for (const regionEnumValue in this.regions) {
      const group: {region: string; stores: Array<Store>} = {region: regionEnumValue, stores: []};
      this.stores.filter(s => s.region === regionEnumValue).forEach(
          s => group.stores.push(s)
      )
      if(group.stores.length > 0){
        this.groupedStores.push(group);
      }
    }

  }

  hasAnySocialLink(store: Store): boolean {
    return store.socials != null
        && (store.socials.facebook != null
            || store.socials.instagram != null
            || store.socials.twitter != null)
  }
}
