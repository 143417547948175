









































































import Component from "vue-class-component";
import Modal from "@/components/modals/Modal.vue";
import Vue from "vue";
import {User} from "@/store/modules/strapi/types";
import {Strapi} from "@/store/modules/strapi/service";
import LoginManager from "@/login-manager";
/* eslint @typescript-eslint/no-var-requires: "off" */
const { EventBus } = require("@/event-bus");

@Component({
  components: {
    Modal
  }
})
export default class AccountModal extends Vue {
  user: User|null = null;
  askDeleteConfirmation = false;
  askResetConfirmation = false;
  disableReset = false;
  resetError = false;

  async beforeCreate(){
    this.user = await Strapi.instance().getSelf().then(r => r.data);
  }

  get fullName() {
    return `${this.user?.firstName} ${this.user?.lastName}`;
  }

  get hasResetError() {
    return this.resetError && !this.askResetConfirmation;
  }

  closeAccountModal(){
    EventBus.$emit('account-modal');
  }

  async resetPassword() {
    this.askResetConfirmation = false;
    this.resetError = false;
    if(this.user) {
      this.disableReset = true;
      const payload = {email: this.user.email};
      await Strapi.instance().resetPasswordRequest(payload)
          .then(() => this.disableReset = false)
          .catch(() => this.resetError = true)
    } else {
      alert(this.$t('errorOccurredContact'));
    }
  }

  async deleteAccount() {
    await Strapi.instance().deleteUser()
        .then(() => EventBus.$emit('account-deleted-modal'))
        .then(() => LoginManager.instance().logout())
        .then(() => this.closeAccountModal());
  }
}
