













































































































































/* eslint @typescript-eslint/no-var-requires: "off" */
import Vue from "vue";
import Component from "vue-class-component";
import Modal from "@/components/modals/Modal.vue";
import {LegalDocs, LoginPayload, LoginResult, RegistrationPayload} from "@/store/modules/strapi/types";
import {Strapi} from "@/store/modules/strapi/service";
import LoginManager from "@/login-manager";
import {TranslateResult} from "vue-i18n";

const {EventBus} = require("@/event-bus");

@Component({
  components: {
    Modal
  }
})
export default class LoginModal extends Vue {

  loginForm: LoginPayload = {identifier: '', password: ''};
  registrationForm: RegistrationPayload = {
    email: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    companyName: '',
    vatNumber: '',
    acceptedTerms: false,
    acceptedPrivacy: false
  };
  loginFailed = false;
  registrationFailed = false;
  disableRegistration = false;
  loading = false;
  submitted = false;
  registering = false;
  loginErrorMessage: TranslateResult = '';

  registrationErrorMessage: TranslateResult = '';

  modalContainerClasses: Array<string> = [
    'login-modal-container'
  ];

  legalDocs: LegalDocs | null = null;

  async mounted() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (this.$refs.usernameInput as any).focus();
    this.legalDocs = await Strapi.instance().getLegalDocs()
        .then(r => r.data)
        .then(docs => {
          docs.cookies.url = process.env.VUE_APP_API_URI + docs.cookies.url;
          docs.privacy.url = process.env.VUE_APP_API_URI + docs.privacy.url;
          docs.terms.url = process.env.VUE_APP_API_URI + docs.terms.url;
          return docs;
        });
  }

  get usernameError() {
    const regx = /^\S+@\S+\.\S+$/;
    return (!this.loginForm.identifier && !this.submitted) || regx.test(this.loginForm.identifier)
        ? null : ` - ${this.$t('inputValidEmail')}`;
  }

  get passwordError() {
    const regx = /^[\s\S]{8,32}$/i;
    if (this.submitted) {
      if (!this.loginForm.password) {
        return this.registering ? ` - ${this.$t('requiredField')}` : ` - ${this.$t('writePassword')}`;
      }
      if (this.registering && !regx.test(this.loginForm.password)) {
        return ` - ${this.$t('passwordComplexity')}`;
      }
    }
    return null;
  }

  get anyLoginValidationErrors(): boolean {
    return this.submitted && (this.usernameError != null || this.passwordError != null);
  }

  get confirmPasswordError() {

    if (this.registering) {
      if (this.loginForm.password) {
        if (!this.registrationForm.confirmPassword && this.submitted) {
          return ` - ${this.$t('requiredField')}`;
        } else if (this.registrationForm.confirmPassword) {
          return this.loginForm.password.localeCompare(this.registrationForm.confirmPassword) !== 0 ?
              ` - ${this.$t('differentPasswords')}` : null;
        }
      }
    }
    return null;
  }

  get firstNameError() {
    return this.validateName(this.registrationForm.firstName);
  }

  get lastNameError() {
    return this.validateName(this.registrationForm.lastName);
  }

  private validateName(name: string | null) {
    const regx = /^.{1,64}$/i;
    const shouldShowErrors = this.registering && this.submitted;
    if (shouldShowErrors) {
      if (!name) {
        return ` - ${this.$t('requiredField')}`;
      } else if (!regx.test(name)) {
        return ` - ${this.$t('nameMaxLength')}`;
      }
    }
    return null;
  }

  get companyNameError() {
    const shouldShowErrors = this.registering && this.submitted;
    if(shouldShowErrors) {
      if(!this.registrationForm.companyName){
        return ` - ${this.$t('requiredField')}`;
      }
      if(this.registrationForm.companyName.length > 254){
        return ` - ${this.$t('companyNameMaxLength')}`;
      }
    }
    return null;
  }

  get vatNumberError() {
    const regx = /^((AT)?U[0-9]{8}|(BE)?0[0-9]{9}|(BG)?[0-9]{9,10}|(CY)?[0-9]{8}L|(CZ)?[0-9]{8,10}|(DE)?[0-9]{9}|(DK)?[0-9]{8}|(EE)?[0-9]{9}|(EL|GR)?[0-9]{9}|(ES)?[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)?[0-9]{8}|(FR)?[0-9A-Z]{2}[0-9]{9}|(GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|(HU)?[0-9]{8}|(IE)?[0-9]S[0-9]{5}L|(IT)?[0-9]{11}|(LT)?([0-9]{9}|[0-9]{12})|(LU)?[0-9]{8}|(LV)?[0-9]{11}|(MT)?[0-9]{8}|(NL)?[0-9]{9}B[0-9]{2}|(PL)?[0-9]{10}|(PT)?[0-9]{9}|(RO)?[0-9]{2,10}|(SE)?[0-9]{12}|(SI)?[0-9]{8}|(SK)?[0-9]{10})$/
    const shouldShowErrors = this.registering && this.submitted;
    if (shouldShowErrors) {
      if (!this.registrationForm.vatNumber) {
        return ` - ${this.$t('requiredField')}`;
      }
      if (!regx.test(this.registrationForm.vatNumber)) {
        return ` - ${this.$t('notValidVat')}`;
      }
    }
    return null;
  }

  get termsError() {
    const shouldShowError = this.submitted && this.registering;
    return shouldShowError && !this.registrationForm.acceptedTerms ? ` - ${this.$t('requiredField')}` : null;
  }

  get privacyError() {
    const shouldShowError = this.submitted && this.registering;
    return shouldShowError && !this.registrationForm.acceptedPrivacy ? ` - ${this.$t('requiredField')}` : null;
  }

  get anyRegistrationValidationErrors() {
    return this.submitted && (
        this.anyLoginValidationErrors ||
        this.confirmPasswordError != null ||
        this.firstNameError != null ||
        this.lastNameError != null ||
        this.vatNumberError != null ||
        this.termsError != null ||
        this.companyNameError != null ||
        this.privacyError != null);
  }

  resetErrors() {
    this.loginFailed = false;
  }

  emitConfirmEmailModal() {
    EventBus.$emit('confirm-email-modal');
  }

  emitLoginModalClose() {
    EventBus.$emit('login-modal-close');
  }

  async login() {
    this.submitted = true;
    if (this.registering || this.anyLoginValidationErrors) {
      return;
    }

    this.loading = true;
    await this.delayLogin();
    const response = await Strapi.instance().login(this.loginForm)
        .catch(error => error.response);
    this.loading = false;

    if (response.status === 400) {
      await this.incorrectLogin();
    }

    switch (response.status) {
      case 400:
        await this.incorrectLogin();
        return;
      case 200:
        await this.finalizeLogin(response.data);
        return;
      default:
        console.error(`Login failed`);
        this.loginFailed = true;
        this.loginErrorMessage = this.$t('errorOccurredContact')
    }

  }

  delayLogin(): Promise<void> {
    return new Promise<void>(
        resolve => {
          resolve();
          // const attempts = LoginManager.getAttempts();
          // if (attempts < 1) {
          //   return setTimeout(resolve, 250);
          // }
          // const timeout = Math.pow(1.85, attempts - 2) * 1000;
          // return setTimeout(resolve, timeout);
        }
    );
  }

  private async incorrectLogin() {
    console.error(`Login failed`);
    this.loginFailed = true;
    this.loginErrorMessage = this.$t('checkCredentials')
    await this.errorWiggle();
    this.loginForm.password = '';
  }

  private async errorWiggle() {
    this.modalContainerClasses.push('wiggle');
    await new Promise(
        resolve => {
          setTimeout(() => {
            this.modalContainerClasses
                .splice(this.modalContainerClasses.indexOf('wiggle'), 1);
            resolve();
          }, 600);
        }
    );
  }

  private finalizeLogin(response: LoginResult) {
    LoginManager.instance().saveLogin(response);
    EventBus.$emit('login-event');
    this.emitLoginModalClose();
  }

  async registerUser() {
    this.submitted = true;

    if (this.anyRegistrationValidationErrors) {
      this.registrationErrorMessage = this.$t('signupValidationError');
      return;
    }

    if (!this.disableRegistration) {
      this.registrationForm.email = this.loginForm.identifier;
      this.registrationForm.password = this.loginForm.password;
      await Strapi.instance()
          .register(this.registrationForm)
          // .then(r => this.finalizeLogin(r.data))
          .then(() => this.emitLoginModalClose())
          .then(() => this.emitConfirmEmailModal())
          .catch(error => this.handleRegistrationError(error));
    }
  }

  /* bello sto troiaio */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private handleRegistrationError(error: any) {
    console.error('Registration failed.', error);
    this.registrationFailed = true;
    this.disableRegistration = true;
    setTimeout(() => this.disableRegistration = false, 3000);
    const response = error.response.data;
    if (response.statusCode === 400) {
      const messages = response.data[0]?.messages;
      if (!messages) {
        this.registrationErrorMessage = this.$t('errorOccurred');
      }

      if (messages && messages.length > 0) {
        this.registrationErrorMessage = messages[0].message;
        if (messages[0].id.endsWith('email.taken')) {
          this.registrationErrorMessage = this.$t('emailTaken');
        }
      }
    } else {
      this.registrationErrorMessage = this.$t('errorOccurred');
    }
  }

}
