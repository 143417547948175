














/* eslint @typescript-eslint/no-var-requires: "off" */
import TheNavbar from '@/components/navbar/TheNavbar.vue';
import Vue from "vue";
import Component from "vue-class-component";

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {faFacebook, faInstagram, faTwitter} from '@fortawesome/free-brands-svg-icons'
import AppSlideshow from "@/components/slideshow/AppSlideshow.vue";
import {faAt, faMapMarker, faMobileAlt, faPhoneAlt} from "@fortawesome/free-solid-svg-icons";
import {faTimesCircle} from "@fortawesome/free-regular-svg-icons";
import LoginModal from "@/components/modals/login/LoginModal.vue";
import AccountModal from "@/components/modals/login/AccountModal.vue";
import AccountDeletedModal from "@/components/modals/login/AccountDeletedModal.vue";
import ConfirmEmailModal from "@/components/modals/login/ConfirmEmailModal.vue";
import EmailConfirmationSuccess from "@/components/modals/login/EmailConfirmationSuccess.vue";

import {MetaInfo} from "vue-meta";
const { EventBus } = require("@/event-bus");

library.add(faInstagram, faTwitter, faFacebook);
library.add(faMapMarker, faPhoneAlt, faMobileAlt, faAt, faTimesCircle);
Vue.component('font-awesome-icon', FontAwesomeIcon);

@Component({
  components: {
    AppNavbar: TheNavbar,
    AppSlideshow,
    LoginModal,
    AccountModal,
    AccountDeletedModal,
    ConfirmEmailModal,
    EmailConfirmationSuccess
  },
  metaInfo(): MetaInfo {
    return (this as App).metaTags;
  }
})
export default class App extends Vue {
  showLogin = false;
  showAccount = false;
  showAccountDeleted = false;
  showConfirmEmail = false;
  showEmailConfirmationSuccess = false;

  mounted() {
    this.registerEvents();
    if(this.$route.query.emailConfirmed == 'true'){
      this.showEmailConfirmationSuccess = true;
    }
  }

  private registerEvents() {
    EventBus.$on('login-modal', () => this.showLogin = true);
    EventBus.$on('login-modal-close', () => this.showLogin = false);
    EventBus.$on('account-modal', () => this.showAccount = !this.showAccount);
    EventBus.$on('account-deleted-modal', () => this.showAccountDeleted = !this.showAccountDeleted);
    EventBus.$on('confirm-email-modal', () => this.showConfirmEmail = true);
    EventBus.$on('confirm-email-modal-close', () => this.showConfirmEmail = false);
    EventBus.$on('email-confirmed-modal-close', () => {
      this.showEmailConfirmationSuccess = false;
      this.$router.replace('/');
    });
  }

  get metaTags(): MetaInfo {
    return {
      // title: 'Massimo Baldi ®',
      titleTemplate: (titleChunk) => {
        // If undefined or blank then we don't need the pipe
        return titleChunk ? `${titleChunk} | Massimo Baldi ®` : 'Massimo Baldi ®';
      },
      htmlAttrs: {
        lang: this.$i18n.locale.startsWith('it') ? 'it-IT' : 'en-US'
      }
    }
  }
}
